import { LabelHTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.blue};
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`

interface MessageProps {
  error?: boolean
}

const Message = styled.span<MessageProps>`
  color: ${(props) =>
    (props.error ?? false) ? props.theme.colors.red : 'inherit'};
`

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  loading?: boolean
  error?: string
}

export function Label({
  children,
  loading = false,
  error,
  ...props
}: PropsWithChildren<LabelProps>) {
  const { t } = useTranslation()
  return (
    <StyledLabel {...props}>
      <span>{children}:</span>
      <Message error={error !== undefined}>
        {error ?? (loading ? t('forms.label.saving') : '')}
      </Message>
    </StyledLabel>
  )
}
