import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 10px;
`

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`

export interface PlaylistInfoProps {
  playlist?: string
}

export function PlaylistInfo({ playlist }: PlaylistInfoProps) {
  const { t } = useTranslation()

  return (
    <Container>
      <Wrapper>
        <Label>
          <Icon icon={faPlayCircle} />
          {t('screens.playlist')}:
        </Label>
        <Title>{playlist ?? t('screens.noPlaylist')}</Title>
      </Wrapper>
    </Container>
  )
}
