import {
  BaseSyntheticEvent,
  ChangeEventHandler,
  useCallback,
  useRef,
} from 'react'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClickAway } from 'react-use'
import styled from 'styled-components'
import { ScrollBar } from 'src/components/ScrollBar'
import { device } from 'src/device'
import { useToggleOpener } from 'src/hooks/useToggleOpener'
import { ListOptionItem } from './ListOptionItem'

export interface WrapperProps {
  opened: boolean
  hasValue: boolean
}

const Container = styled.div`
  position: relative;
`

const Wrapper = styled.div<WrapperProps>`
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px 5px ${(props) => (props.opened ? '0 0' : '5px 5px')};
  border: 1px solid ${(props) => props.theme.colors.grayMedium};
  outline: none;
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: 16px;
  font-weight: ${(props) => (props.hasValue ? 'bold' : 'normal')};
  font-style: ${(props) => (props.hasValue ? 'normal' : 'italic')};
  width: 100%;
  position: relative;
  cursor: pointer;
`

const HiddenSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;

  @media ${device.tablet} {
    pointer-events: auto;
  }
`

interface ListWrapperProps {
  opened: boolean
}

const ListWrapper = styled.div<ListWrapperProps>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.grayMedium};
  border-top: none;
  max-height: 160px;
  height: 100vh;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  pointer-events: ${(props) => (props.opened ? 'auto' : 'none')};
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  padding: 10px 4px 10px 10px;
  z-index: 1000;

  @media ${device.tablet} {
    display: none;
  }
`

const ListInnerWrapper = styled.div`
  padding-right: 2px;
`

const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const OpenIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`

const Value = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
interface Options {
  [key: string]: string
}

export interface SelectProps {
  value: string | null
  onChange: (value: string | null) => void
  placeholder?: string
  options: Options
  emptyTitle?: string
}

export function Select({
  value,
  onChange,
  placeholder,
  options,
  emptyTitle,
}: SelectProps) {
  const onSelectChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    ({ target: { value } }) => onChange(value !== '' ? value : null),
    [onChange]
  )

  const [opened, toggle, close] = useToggleOpener()

  const onSelect = useCallback(
    (value: string | null) => {
      onChange(value)
      close()
    },
    [onChange, close]
  )

  const preventClick = useCallback((event: BaseSyntheticEvent) => {
    event.stopPropagation()
    return false
  }, [])

  const selectContainer = useRef(null)
  useClickAway(selectContainer, close)

  return (
    <Container ref={selectContainer}>
      <Wrapper opened={opened} onClick={toggle} hasValue={value !== null}>
        <ValueWrapper>
          <Value>{value !== null ? options[value] : placeholder}</Value>
          <OpenIcon icon={faCaretDown} />
        </ValueWrapper>
        <HiddenSelect
          value={value ?? undefined}
          onChange={onSelectChange}
          onClick={preventClick}
        >
          {emptyTitle !== undefined && (
            <option key="empty" value="">
              {emptyTitle}
            </option>
          )}
          {Object.keys(options).map((key) => (
            <option key={`value-${key}`} value={key}>
              {options[key]}
            </option>
          ))}
        </HiddenSelect>
      </Wrapper>
      <ListWrapper opened={opened}>
        <ScrollBar>
          <ListInnerWrapper>
            {emptyTitle !== undefined && (
              <ListOptionItem
                key="empty"
                name={emptyTitle}
                value={null}
                isEmpty
                onSelect={onSelect}
              />
            )}
            {Object.keys(options).map((key) => (
              <ListOptionItem
                key={`value-${key}`}
                name={options[key]}
                value={key}
                onSelect={onSelect}
              />
            ))}
          </ListInnerWrapper>
        </ScrollBar>
      </ListWrapper>
    </Container>
  )
}
