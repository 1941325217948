import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { gql } from 'src/graphql'
import { useDebouncedInput } from 'src/hooks/useDebouncedInput'
import { withFragments } from 'src/libraries/graphql-fragments'

export const Name = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment ScreenNameFragment on Screen {
        id
        name
      }
    `),
  },
  function Name({ screen }) {
    const { t } = useTranslation()

    const [renameScreenMutation] = useMutation(
      gql(/* GraphQL */ `
        mutation renameScreen($id: ID!, $name: String!) {
          renameScreen(id: $id, name: $name) {
            id
            name
          }
        }
      `)
    )

    const { id } = screen

    const onNameChange = useCallback(
      async (name: string) => {
        await renameScreenMutation({
          variables: {
            id,
            name,
          },
        })
      },
      [id, renameScreenMutation]
    )

    const [name, onNameChangeEvent, nameSaving] = useDebouncedInput(
      screen.name,
      onNameChange
    )

    return (
      <FormGroup>
        <Label loading={nameSaving}>
          {t('screens.edit.settings.name.label')}
        </Label>
        <Input name="name" value={name} onChange={onNameChangeEvent} />
      </FormGroup>
    )
  }
)
