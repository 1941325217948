import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/elements/Button'
import { gql } from 'src/graphql'
import { useOpener } from 'src/hooks/useOpener'
import { withFragments } from 'src/libraries/graphql-fragments'
import { PairingModal } from '../pairing/PairingModal'

export const PairingButton = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment ScreenPairingButtonFragment on Screen {
        id
        name
      }
    `),
  },
  function PairingButton({ screen }) {
    const { id, name } = screen
    const [isPairingOpened, openPairing, closePairing] = useOpener('pair')
    const client = useApolloClient()
    const [pairMutation] = useMutation(
      gql(/* GraphQL */ `
        mutation registerDevice($screenId: ID!, $code: String!) {
          registerDevice(screenId: $screenId, userCode: $code) {
            deviceCode
          }
        }
      `),
      {
        onCompleted: async () => {
          await client.refetchQueries({
            include: 'active',
          })
        },
      }
    )

    const pair = useCallback(
      async (code: string) => {
        await pairMutation({
          variables: {
            screenId: id,
            code,
          },
        })
        closePairing()
      },
      [id, pairMutation, closePairing]
    )

    const { t } = useTranslation()

    return (
      <div>
        <Button
          label={t('screens.pairing.activateButton')}
          onClick={openPairing}
        />
        <PairingModal
          onPair={pair}
          screenName={name}
          onClose={closePairing}
          show={isPairingOpened}
        />
      </div>
    )
  }
)
