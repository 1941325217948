import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { createMongoAbility } from '@casl/ability'
import { useAtom } from 'jotai'
import { gql } from 'src/graphql'
import { abilityAtom } from './abilityAtom'

export function useInitAbility() {
  const permissionsQuery = useQuery(
    gql(/* GraphQL */ `
      query permissions {
        permissions
      }
    `)
  )

  const permissions = permissionsQuery.data?.permissions

  const [ability, setAbility] = useAtom(abilityAtom)

  useEffect(() => {
    if (permissions === undefined) {
      setAbility(undefined)
    } else {
      setAbility(createMongoAbility(JSON.parse(permissions)))
    }

    return () => {
      setAbility(undefined)
    }
  }, [permissions, setAbility])

  return { loaded: ability !== undefined, ability }
}
