import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { faPlay, faPlug } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Card } from 'src/components/Card'
import { gql } from 'src/graphql'
import { useOpener } from 'src/hooks/useOpener'
import { withFragments } from 'src/libraries/graphql-fragments'
import { PlaylistInfo } from './PlaylistInfo'
import { PairingModal } from './pairing/PairingModal'
import { PlaylistSelectorModal } from './playlist-selector/PlaylistSelectorModal'

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`

const Left = styled.div`
  flex: 1;
  margin: 0 10px;
  width: 50%;
`

const Right = styled.div`
  margin: 0 10px;
`

const Label = styled.div`
  color: ${(props) => props.theme.colors.blue};
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Title = styled.div`
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const IconWrapper = styled.div`
  display: flex;
  margin: 0 -15px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.primary};
  margin: 0 15px;
  cursor: pointer;
`

export const ScreenItemDeprecated = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment ScreenItemDeprecatedFragment on Screen {
        id
        name
        slideshow {
          id
          name
          slides {
            media {
              id
              thumbnailUrl
            }
          }
        }
      }
    `),
  },
  function ScreenItemDeprecated({ screen: { id, name, slideshow } }) {
    const playlist = slideshow?.name
    const image = slideshow?.slides[0]?.media.thumbnailUrl ?? null
    const { t } = useTranslation()

    const [
      isPlaylistSelectorOpened,
      openPlaylistSelector,
      closePlaylistSelector,
    ] = useOpener('play')

    const [setSlideshowMutation] = useMutation(
      gql(/* GraphQL */ `
        mutation setSlideshow($screenId: ID!, $slideshowId: ID) {
          setSlideshow(screenId: $screenId, slideshowId: $slideshowId) {
            id
            slideshow {
              id
            }
          }
        }
      `)
    )

    const selectPlaylist = useCallback(
      async (playlistId: string | null) => {
        await setSlideshowMutation({
          variables: {
            screenId: id,
            slideshowId: playlistId,
          },
        })
        closePlaylistSelector()
      },
      [id, setSlideshowMutation, closePlaylistSelector]
    )

    const [isPairingOpened, openPairing, closePairing] = useOpener('pair-old')

    const client = useApolloClient()
    const [pairMutation] = useMutation(
      gql(/* GraphQL */ `
        mutation registerDevice($screenId: ID!, $code: String!) {
          registerDevice(screenId: $screenId, userCode: $code) {
            deviceCode
          }
        }
      `),
      {
        onCompleted: async () => {
          await client.refetchQueries({
            include: 'active',
          })
        },
      }
    )

    const pair = useCallback(
      async (code: string) => {
        await pairMutation({
          variables: {
            screenId: id,
            code,
          },
        })
        closePairing()
      },
      [id, pairMutation, closePairing]
    )

    return (
      <Card
        data-test="screen-card"
        image={image}
        hideOverlay={playlist === undefined}
        imageChildren={<PlaylistInfo playlist={playlist} />}
      >
        <ContentWrapper>
          <Left>
            <Label>{t('screens.name')}:</Label>
            <Title>{name}</Title>
          </Left>
          <Right>
            <IconWrapper>
              <Icon
                icon={faPlay}
                onClick={openPlaylistSelector}
                data-test="playlist-selector-button"
              />
              <Icon
                icon={faPlug}
                onClick={openPairing}
                data-test="activation-button"
              />
            </IconWrapper>
          </Right>
        </ContentWrapper>
        <PlaylistSelectorModal
          playlistId={slideshow?.id ?? null}
          onPlaylistSelected={selectPlaylist}
          onClose={closePlaylistSelector}
          screenName={name}
          show={isPlaylistSelectorOpened}
        />
        <PairingModal
          onPair={pair}
          screenName={name}
          onClose={closePairing}
          show={isPairingOpened}
        />
      </Card>
    )
  }
)
