import { useEffect, useMemo } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { gql } from 'src/graphql'
import { ConnectionStatus } from 'src/graphql/graphql'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { selectedOrganizationIdAtom } from 'src/organizations/selectedOrganizationIdAtom'
import { ScreenList } from './ScreenList'
import { ScreenListDeprecated } from './ScreenListDeprecated'

const Header = styled.div`
  margin-bottom: 60px;
`

export function Screens() {
  useScrollToTop()
  const client = useApolloClient()
  const selectedOrganizationId = useAtomValue(selectedOrganizationIdAtom)

  if (selectedOrganizationId === null) {
    throw new Error('No organization selected')
  }

  const { t } = useTranslation()
  const query = useQuery(
    gql(/* GraphQL */ `
      query getScreensAndSlideshows($organizationId: String!) {
        screens(organizationId: $organizationId) {
          presence {
            status
          }
          ...ScreenListFragment
          ...ScreenListDeprecatedFragment
        }
      }
    `),
    {
      variables: {
        organizationId: selectedOrganizationId,
      },
    }
  )

  const screens = query.data?.screens
  const totalCount = screens ? screens.length : 0
  const connectedCount = useMemo(
    () =>
      screens
        ? screens.filter(
            (screen) => screen.presence.status === ConnectionStatus.Connected
          ).length
        : 0,
    [screens]
  )

  useEffect(() => {
    const subscription = client
      .subscribe({
        query: gql(/* GraphQL */ `
          subscription OnScreenChanged($organizationId: ID!) {
            onScreenChanged(organizationId: $organizationId) {
              id
              presence {
                status
                lastActivity
              }
            }
          }
        `),
        variables: {
          organizationId: selectedOrganizationId,
        },
      })
      .subscribe({})

    return () => {
      subscription.unsubscribe()
    }
  }, [selectedOrganizationId, client])

  const editDisplayPage = useFlag('edit-display-page-240')
  const showScreenStatus = useFlag('show-screen-presence-status-77')

  return (
    <div>
      <Header>
        <TitleH2>{t('screens.title')}</TitleH2>
        {showScreenStatus ? (
          <SubTitle>
            {screens !== undefined ? (
              <>
                {t('screens.subtitle', {
                  count: connectedCount,
                })}{' '}
                /{' '}
                {t('screens.subtitle_total', {
                  count: totalCount,
                })}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </SubTitle>
        ) : (
          <SubTitle>
            {screens !== undefined ? (
              t('screens.subtitle', {
                count: totalCount,
              })
            ) : (
              <>&nbsp;</>
            )}
          </SubTitle>
        )}
      </Header>
      {editDisplayPage ? (
        <ScreenList screens={screens} />
      ) : (
        <ScreenListDeprecated screens={screens} />
      )}
    </div>
  )
}
