import {
  ChangeEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH3 } from 'src/elements/TitleH3'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'

const Wrapper = styled.div`
  max-width: 400px;
`

const FormWrapper = styled.form`
  padding-top: 20px;
`

export interface AddScreenFormProps {
  onAddScreen: (name: string) => void
}

export function AddScreenForm({ onAddScreen }: AddScreenFormProps) {
  const { t } = useTranslation()

  const [name, setName] = useState<string>('')

  const onNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setName(event.target.value),
    [setName]
  )

  const save = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      onAddScreen(name)
    },
    [onAddScreen, name]
  )

  return (
    <Wrapper>
      <TitleH3>{t('screens.screen.addScreen.title')}</TitleH3>
      <SubTitle>{t('screens.screen.addScreen.description')}</SubTitle>
      <FormWrapper onSubmit={save}>
        <FormGroup>
          <Label>{t('screens.screen.addScreen.screenName.title')}</Label>
          <Input
            placeholder={t('screens.screen.addScreen.screenName.placeholder')}
            value={name}
            onChange={onNameChange}
            autoFocus
          />
        </FormGroup>
        <Button
          as="button"
          fullWidth
          label={t('screens.screen.addScreen.saveButton')}
          icon={faPlusCircle}
          type="submit"
        />
      </FormWrapper>
    </Wrapper>
  )
}
