import { createStore } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const selectedOrganizationIdAtom = atomWithStorage<string | null>(
  'selectedOrganizationId',
  null
)

const store = createStore()

export function resetSelectedOrganizationId() {
  store.set(selectedOrganizationIdAtom, null)
}
