import { useCallback, useState, useEffect } from 'react'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Logo } from 'src/components/logo/Logo'
import { Box } from 'src/elements/Box'
import { Button } from 'src/elements/Button'
import { TitleH2 } from 'src/elements/TitleH2'

const Content = styled.div`
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLogo = styled(Logo)`
  margin-bottom: 40px;
`

const StyledTitle = styled(TitleH2)`
  margin-bottom: 40px;
`

const StyledDescription = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
`

const StyledBox = styled(Box)`
  width: 100%;
`

const Error = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: 14px;
  margin-top: 20px;
`

export interface AuthPageProps {
  defaultField?: string
  defaultValues?: Record<string, string>
  title: string
  description?: string
  onSubmit: (fields: any) => Promise<void>
  children: React.ReactNode
  error: string
  submitButtonTitle: string
  submitButtonIcon: IconDefinition
  submitButtonTestId: string
}

export function AuthPage({
  defaultField,
  defaultValues,
  title,
  description,
  onSubmit,
  children,
  error,
  submitButtonTitle,
  submitButtonIcon,
  submitButtonTestId,
}: AuthPageProps) {
  const [hasError, setError] = useState(false)

  const form = useForm({ defaultValues })

  const { reset, setFocus, handleSubmit } = form

  useEffect(() => {
    if (defaultField !== undefined) {
      setFocus(defaultField)
    }
  }, [setFocus, defaultField])

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    }
  }, [reset, defaultValues])

  const onFormSubmit = useCallback(
    async (fields: Record<string, string>) => {
      try {
        setError(false)
        await onSubmit(fields)
      } catch (e) {
        console.error(e)
        setError(true)
      }
    },
    [onSubmit, setError]
  )

  return (
    <Content>
      <Wrapper>
        <StyledLogo />
        <StyledTitle>{title}</StyledTitle>
        {description !== undefined && (
          <StyledDescription>{description}</StyledDescription>
        )}
        <StyledBox>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              {children}
              <Button
                label={submitButtonTitle}
                icon={submitButtonIcon}
                fullWidth
                onClick={handleSubmit(onFormSubmit)}
                data-test={submitButtonTestId}
              />
              {hasError && <Error>{error}</Error>}
            </form>
          </FormProvider>
        </StyledBox>
      </Wrapper>
    </Content>
  )
}
