import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

interface WrapperProps {
  fullWidth: boolean
  secondary: boolean
}

const Wrapper = styled.a<WrapperProps>`
  display: inline-flex;
  height: 50px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  border-radius: 5px;
  border: none;
  background-color: ${({ secondary, theme }) =>
    secondary ? 'transparent' : theme.colors.primary};
  color: ${({ secondary, theme }) =>
    secondary ? theme.colors.darkBlue : theme.colors.white} !important;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ secondary }) => (secondary ? 500 : 800)};

  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-right: 10px;
`

const Label = styled.div`
  line-height: 20px;
  padding-top: 2px; // Exo Font is not precisely align vetically
`

export type ButtonProps = {
  as?: 'a' | 'button'
  icon?: IconProp
  label: string
  fullWidth?: boolean
  secondary?: boolean
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

export function Button({
  as = 'a',
  icon,
  label,
  fullWidth = false,
  secondary = false,
  ...props
}: ButtonProps) {
  return (
    <Wrapper as={as} fullWidth={fullWidth} secondary={secondary} {...props}>
      {icon !== undefined && <Icon icon={icon} />}
      <Label>{label}</Label>
    </Wrapper>
  )
}
