import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useAtom } from 'jotai'
import { useAuthStore } from 'src/auth/useAuthStore'
import { Loader } from 'src/components/Loader'
import { gql } from 'src/graphql'
import { Layout } from 'src/layout/Layout'
import { selectedOrganizationIdAtom } from 'src/organizations/selectedOrganizationIdAtom'
import { OrganizationView } from './OrganizationView'

interface EnsureOnboardingProps {
  children: React.ReactNode
}

export function EnsureOnboarding({ children }: EnsureOnboardingProps) {
  const isLoggedIn = useAuthStore(({ isLoggedIn }) => isLoggedIn)
  const [selectedOrganizationId, setSelectedOrganizationId] = useAtom(
    selectedOrganizationIdAtom
  )

  const { data, refetch } = useQuery(
    gql(/* GraphQL */ `
      query Organizations {
        organizations {
          id
          name
        }
      }
    `),
    {
      skip: isLoggedIn !== true,
    }
  )

  const hasOrganizations = data !== undefined && data.organizations.length > 0

  useEffect(() => {
    if (hasOrganizations && selectedOrganizationId === null) {
      setSelectedOrganizationId(data.organizations[0].id)
    }
  }, [
    data?.organizations,
    hasOrganizations,
    selectedOrganizationId,
    setSelectedOrganizationId,
  ])

  return (
    <>
      {selectedOrganizationId !== null ? (
        children
      ) : (
        <Layout>
          {data === undefined ? (
            <Loader />
          ) : (
            <OrganizationView onCompleted={refetch} />
          )}
        </Layout>
      )}
    </>
  )
}
