import { SyntheticEvent, useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { faPlug } from '@fortawesome/pro-regular-svg-icons'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { Box } from 'src/elements/Box'
import { Button } from 'src/elements/Button'
import { TitleH2 } from 'src/elements/TitleH2'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { gql } from 'src/graphql'
import { BackIcon } from 'src/layout/BackIcon'
import { NavigationSlotContent } from 'src/layout/NavigationSlot'
import { selectedOrganizationIdAtom } from 'src/organizations/selectedOrganizationIdAtom'
import { Section } from 'src/playlists/edit/Section'
import { useCode } from './useCode'

const Header = styled.div`
  margin-bottom: 60px;
`

const StyledBackIcon = styled(BackIcon)`
  display: block;

  @media ${device.mobile} {
    display: none;
  }
`

const Separator = styled.div`
  &:after {
    content: '-';
    display: block;
    width: 30px;
    text-align: center;
    color: ${(props) => props.theme.colors.blue};
    font-size: 16px;
  }
`

const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FormWrapper = styled.form`
  padding-top: 20px;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  width: 75%;
  max-width: 400px;

  @media ${device.tablet} {
    width: 100%;
  }
`

const ErrorText = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: 14px;
  margin-top: 20px;
`

export function PairScreen() {
  const { t } = useTranslation()

  const selectedOrganizationId = useAtomValue(selectedOrganizationIdAtom)

  if (selectedOrganizationId === null) {
    throw new Error('No organization selected')
  }

  const { code, firstProps, secondProps, thirdProps } = useCode()

  const [name, setName] = useState('')

  const navigate = useNavigate()

  const [pairMutation, pairMutationResult] = useMutation(
    gql(/* GraphQL */ `
      mutation pairScreenWithName(
        $name: String!
        $code: String!
        $organizationId: String!
      ) {
        pairScreenWithName(
          name: $name
          code: $code
          organizationId: $organizationId
        ) {
          id
        }
      }
    `),
    {
      onCompleted: ({ pairScreenWithName: { id } }) => {
        navigate(`/screens/${id}`)
      },
    }
  )

  const save = useCallback(
    async (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      await pairMutation({
        variables: {
          name,
          code,
          organizationId: selectedOrganizationId,
        },
      })
    },
    [code, name, selectedOrganizationId, pairMutation]
  )

  return (
    <div>
      <NavigationSlotContent>
        <StyledBackIcon path="/screens" title={t('routes.screens')} />
      </NavigationSlotContent>
      <Header>
        <TitleH2>{t('screens.pairing.title')}</TitleH2>
      </Header>
      <ContentContainer>
        <ContentWrapper>
          <Section title={t('screens.pairing.title')}>
            <Box>
              <FormWrapper onSubmit={save}>
                <FormGroup>
                  <Label>{t('screens.pairing.nameLabel')}</Label>
                  <Input
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                    data-test="screen-name-input"
                    autoFocus
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t('screens.pairing.codeLabel')}</Label>
                  <CodeWrapper data-test="code-wrapper">
                    <Input centered inputMode="numeric" {...firstProps} />
                    <Separator />
                    <Input centered inputMode="numeric" {...secondProps} />
                    <Separator />
                    <Input centered inputMode="numeric" {...thirdProps} />
                  </CodeWrapper>
                </FormGroup>
                <Button
                  as="button"
                  fullWidth
                  disabled={pairMutationResult.loading}
                  label={t('screens.pairing.activateButton')}
                  icon={faPlug}
                  type="submit"
                />
                {pairMutationResult.error && (
                  <ErrorText>{t('screens.pairing.error')}</ErrorText>
                )}
              </FormWrapper>
            </Box>
          </Section>
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}
