import { MouseEventHandler, PropsWithChildren, useCallback } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Link, LinkProps, useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { MenuItem } from './MenuItem'

interface StyledLinkProps {
  isMatched: boolean
}

const StyledLink = styled(
  ({ isMatched, ...rest }: PropsWithChildren<LinkProps & StyledLinkProps>) => (
    <Link {...rest} />
  )
)`
  display: block;
  border-left: 4px solid transparent;
  margin-left: 0;
  padding-left: 1px;
  padding-right: 5px;
  outline: none;

  @media (hover: hover) {
    &:hover,
    &:focus {
      &,
      > * {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${({
    isMatched,
    theme: {
      colors: { primary },
    },
  }) => (isMatched ? `border-left-color: ${primary};` : '')};
`

export interface MenuItemLinkProps {
  path: string
  icon: IconDefinition
  name: string
  className?: string
  onActiveClicked?: () => void
}

export function MenuItemLink({
  path,
  icon,
  name,
  className,
  onActiveClicked,
}: MenuItemLinkProps) {
  let match = useMatch(path)

  const isMatched = !!match

  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      if (isMatched) {
        e.preventDefault()
        onActiveClicked?.()
      }
    },
    [isMatched, onActiveClicked]
  )

  return (
    <StyledLink
      to={path}
      isMatched={isMatched}
      className={className}
      onClick={handleClick}
    >
      <MenuItem icon={icon} name={name} active={isMatched} />
    </StyledLink>
  )
}
