import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { gql } from 'src/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { SelectOrganization } from './SelectOrganization'

const StyledRow = styled.tr`
  cursor: pointer;
`

const NameColumn = styled.td<{ isSelected: boolean }>`
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
`

const OperationsColumn = styled.td`
  display: flex;
  justify-content: center;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`

interface Props {
  isSelected: boolean
}

export const OrganizationRow = withFragments<Props>()(
  {
    organization: gql(/* GraphQL */ `
      fragment OrganizationRow on Organization {
        id
        name
      }
    `),
  },
  function OrganizationRow({ organization: { id, name }, isSelected }) {
    const navigate = useNavigate()

    const handleNameClick = useCallback(
      (organizationId: string) => {
        navigate(`/organizations/${organizationId}`)
      },
      [navigate]
    )
    return (
      <StyledRow data-test="organization-row">
        <NameColumn isSelected={isSelected} onClick={() => handleNameClick(id)}>
          {name}
        </NameColumn>
        <OperationsColumn>
          <SelectOrganization id={id} />
        </OperationsColumn>
      </StyledRow>
    )
  }
)
