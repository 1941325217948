import { useMemo } from 'react'
import {
  faSignOutAlt,
  faSitemap,
  faTimes,
} from '@fortawesome/pro-light-svg-icons'
import {
  faDesktop,
  faPhotoVideo,
  faPlayCircle,
  faTachometerAlt,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAuthStore } from 'src/auth/useAuthStore'
import { useCan } from 'src/auth/useCan'
import { Logo } from 'src/components/logo/Logo'
import { device } from 'src/device'
import { MenuItem } from './MenuItem'
import { MenuItemLink } from './MenuItemLink'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  height: 100%;

  @media ${device.mobile} {
    justify-content: flex-start;
  }
`

const Nav = styled.nav`
  width: 100%;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${device.mobile} {
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 60px;
  }
`

const CloseIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
  @media ${device.mobile} {
    display: flex;
  }
`

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.blue};
  font-size: 30px;
`

const StyledMenuItemLink = styled(MenuItemLink)`
  margin: 20px 0;

  @media ${device.mobile} {
    margin: 0;
  }
`

const LogoutButtonWrapper = styled.div`
  @media ${device.mobile} {
    padding: 0 5px;
    width: 100%;
  }
`

const createItems = (t: TFunction) => [
  {
    path: '/dashboard',
    name: t('routes.dashboard'),
    icon: faTachometerAlt,
  },
  {
    path: '/screens',
    name: t('routes.screens'),
    icon: faDesktop,
  },
  {
    path: '/slideshows',
    name: t('routes.slideshows.main'),
    icon: faPlayCircle,
  },
  {
    path: '/media',
    name: t('routes.mediaLibrary'),
    icon: faPhotoVideo,
  },
  {
    path: '/organizations',
    name: t('routes.organizations'),
    icon: faSitemap,
    permission: {
      action: 'listAll',
      subject: 'Organization',
    },
  },
]

const bottomItem = (t: TFunction) => ({
  name: t('navigation.logout'),
  icon: faSignOutAlt,
})

export interface SideMenuProps {
  onCloseSidebar: () => void
}

export function SideMenu({ onCloseSidebar }: SideMenuProps) {
  const { t } = useTranslation()

  const logout = useAuthStore(({ logout }) => logout)

  const can = useCan()

  const items = useMemo(() => createItems(t), [t])

  return (
    <Container>
      <Header>
        <Logo />
        <CloseIconWrapper onClick={onCloseSidebar}>
          <CloseIcon icon={faTimes} />
        </CloseIconWrapper>
      </Header>
      <Nav>
        {items
          .filter(({ permission }) => {
            if (permission) {
              return can(permission.action, permission.subject)
            }
            return true
          })
          .map((item) => (
            <StyledMenuItemLink
              {...item}
              key={item.path}
              onActiveClicked={onCloseSidebar}
            />
          ))}
      </Nav>
      <LogoutButtonWrapper>
        <MenuItem onClick={logout} {...bottomItem(t)} />
      </LogoutButtonWrapper>
    </Container>
  )
}
