import { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

export function useDebouncedValue<S>(
  initialValue: S | undefined,
  onChange: (value: S) => Promise<void> | void,
  ms: number = 1000
): [S | undefined, (value: S) => void, boolean] {
  const [value, setValue] = useState(initialValue)
  const [saving, setSaving] = useState<boolean>(false)

  useEffect(() => {
    if (!saving) {
      setValue(initialValue)
    }
  }, [initialValue, saving])

  const onValueChange = useCallback(
    (value: S) => {
      setValue(value)
    },
    [setValue]
  )

  const [isReady] = useDebounce(
    async () => {
      if (value !== undefined && value !== initialValue) {
        await onChange(value)
      }
      setSaving(false)
    },
    ms,
    [value, setSaving]
  )

  useEffect(() => {
    if (
      !(isReady() ?? false) &&
      value !== undefined &&
      value !== initialValue
    ) {
      setSaving(true)
    }
  }, [value, initialValue, isReady, setSaving])

  return [value, onValueChange, saving]
}
