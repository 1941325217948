import { useCallback } from 'react'
import { useAbility } from './useAbility'

export function useCan() {
  const ability = useAbility()

  return useCallback(
    (I: string, a: string) => {
      if (ability === undefined) {
        return false
      }

      return ability.can(I, a)
    },
    [ability]
  )
}
