import { HttpResponse, ResponseResolver } from 'msw'

interface AuthRequest {
  AuthFlow?: string
  ChallengeName?: string
  AccessToken?: string
  Username?: string
  ConfirmationCode?: string
}

let successfulLogin = true
let successfulForgetPassword = true
let successfulResetPassword = true
let successfulNewPassword = true
let forceChangePassword = false

const localStoragePrefix = 'CognitoIdentityServiceProvider'

export const resetMock = () => {
  successfulLogin = true
  successfulForgetPassword = true
  successfulResetPassword = true

  const removableKeys = []

  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i)

    if (key?.startsWith(localStoragePrefix) === true) {
      removableKeys.push(key)
    }
  }

  removableKeys.forEach((key) => {
    window.localStorage.removeItem(key)
  })
}

export const setLoginSuccess = (success: boolean) => {
  successfulLogin = success
}

export const setForceChangePassword = (forceChangePasswordInput: boolean) => {
  forceChangePassword = forceChangePasswordInput
}

export const setNewPasswordSuccess = (success: boolean) => {
  successfulNewPassword = success
}

export const setForgetPasswordSuccess = (success: boolean) => {
  successfulForgetPassword = success
}

export const setResetPasswordSuccess = (success: boolean) => {
  successfulResetPassword = success
}

const userId = '21c61a65-94b6-4167-8fff-9c5fe681ec8c'

export const cognito: ResponseResolver<{}, AuthRequest> = async ({
  request,
}) => {
  const body = await request.json()
  if (body.AuthFlow === 'USER_SRP_AUTH') {
    if (!successfulLogin) {
      return HttpResponse.json({}, { status: 400 })
    }
    return HttpResponse.json({
      ChallengeName: 'PASSWORD_VERIFIER',
      ChallengeParameters: {
        SALT: '2d2376f4c94b1db303630b2733dc5269',
        SECRET_BLOCK:
          'uJ5azujTsWgFCn9RI9/jBi+4UdVwSXXJQthFyptYVJFS6hmH4ywInwY71WA70y+1I/+mge3xrgaQcaDdWViULbYX/zvz4nyEJqAWEvbLyFZRI4+h3w7GRqN+5EtuLy6nfuHU7rQEGiiMvj4lOufNpGFDIH+n+b82mptZB8VQ4U7eWrIt6XhrmT5qe0UzYaHS90Ynmg1SuFfyZw9N6VlrdYpdvXiM1g7ES/Kt/vpHlWl7hcjJmUHMJdy1qOSwFp2MnsI9e4+gFE3/YVsdFSCO51erjq3/GgyvCdXeUlnZoaIzRRIcAw6BoU+DIleWZs0o54CRMZtJvTu30QMVfUXVm0IH5lp3eOHzPaeRFJyya05awKFkICWTmK9rNpCLuymIRi8qf28p9Dx0XOAZMSBXEE6OxSQRsgVZCfsU+JuNP+Si4nlHae3L3TDALfZOJl+qzDR1kpSw2yW9FLpGXXSrYv9jms2mccE9uQG2Am9mqyxvSi/qRsOzmYtDfSJk+cZfMKpz+iHhUq7+n/53u7B5yLjuAc48PbOItFVaip/umLUlwFeUfkhNx7EFW7EVSMDMhlXe0K+FvFZtCAWi6g/ZSlF6mVTucNpGC/8W69C5uxxGjf89g+VBmcgTuNi6/9yWxnZa2tpX8m638TbUPYRTpcFxO/g+rVknm/80SiPN+KQwQjodyQq47WwFcaVUQX2D5u4D59e4kqhDWhvgcUwrcHwrt1+NmYQvg7d8wV7KBOoXoYxkYlRcZ6nuNHehbjhz47pLGSEW0z2e5b0FkGOx4ltwgkasGpciL5ubg4AlKrHxqEeLIdUNBAApv4tkg3PU0jnXs6On1Z+OxQO5Sph/ztiiDzQAxIZ0v33uRDH5NEW4BNk2WwiazypaONSiDpfHXEJYqa28iIs2Rw9JNrTf6d81jaNddmb3LeFeV8BFdkiOYNygEjPJMr8r6yoKRThpRrm8B1RvayvBBYfY6+ZdB5EjaKZ4kRpQyhjlqXhNdGlueMmcZ2O3z4+HDe+KdhQx9zePgzyarkjn+XjIQyVTHwam4s0vs/FGyqMm6gXtdQN/sbuS651Ym08SxL8CNu/yPbFUtjF7BlvaONTBQdtZHyWgtLczHELoumKbKXnricQ+BstJFCycBEajv0BJNahyJI8tc4Hm4auojSqGB4W8hUcHdCkFDF1kqKRSrHnKSrSP0HSB5xhtH9hex0HBf5SOf1uKpeXZWR8vk960Zx1wHNea9gJ6XBvIqs4Xq2vEZsHYwgB0rxvW/1l+dIZZk3O5pdXdMRhJ1iNrp/FzVUO1c437Zzavq+VMXaHZXL9rJ1ZNwvuXZYJWx6tUiIEIsfdEu4tKTiNtImGk8nA+zBPMZet+lZfYvnSRsKbVjpEbwEn7fTLAvLp4SrqNfa5zprYSxI1d6sFeGzvlui1RyCl2DIsqf8t3YIyb4qp5VYq9Q2TkEZKIRqsYXqZd0Lw4lMZ9ROpbluCgeLccYSX4Me5K+NIA8MUCOXyh7HBAxEeJYU1KYRUu2/m8Ush62uwN2/wBILEoRbAiqT693nT3nHDMnEYc98dnFPqLtd95iBjzdEw384bn/0sBvOyHJFCkLrUr5MCRtYflLsnoGLwDsT/+xUAEfq3oDPmTBs+LmiPaSUwtwg4dyfiXf8AraxrkJygsEynmMaV1AV9+XCJGpwY+/9K9EoNwtF9NLPdxxq/p9Cs0cAG/AxlGpn4o+A==',
        SRP_B:
          '4f2b2716cb021c1fee564708b396b9eee5773d59dac010c30e36a8d7dfec54ba691e8dff840a3403287fc90ff1c2b33b868512a47c3f79581ce206998b560630bd6c614e4c1f041cc3dd328290254d103a4fec6869656745f1d4db64a666668060b921698bbcdd1ffa77129e8227484f562c7842fdb5332f85810279584af8bbc4fe6761e02ab9497618ed138807506837007498d7110b36def3d1f7d24a34f25464c9e11757064194b2b9c4481b5b5be361a4d641bd61e86992046916aa6622c12b876feec7d8a773dee01ec4114894c3842b085023027e70ba5fc39a77f316bd57c26c5fbec8df29f659e103f257c75b6724d466eeb0742c54acf2e57e94cc6249d9b6884d7f09490d7413b887bf04baf1f0c2fe84a4eae1659d90ebda6fe96d697bb1e799eee07596f2218aed510f68d4671aa27e86d1f7668684eb4204c4b32fc11b2ac1da39bf1fb07160f84cd7a910bc0a246592dbfbbdfb44f37cb9926782b7d54f9da853c4fe4abaa1b8ced98a372f4e6aa4821c2b649189b4f2742d',
        USERNAME: userId,
        USER_ID_FOR_SRP: userId,
      },
    })
  } else if (body.AuthFlow === 'REFRESH_TOKEN_AUTH') {
    return HttpResponse.json({
      AuthenticationResult: {
        AccessToken:
          'eyJraWQiOiJFNTFIZUNLUzFCTUgybzBrNkR1UWV5dGMycFZSVjlqd3BrVTIrWTJLNlFVPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJldmVudF9pZCI6IjRjZTIwYTRkLTAzMTktNDA1NC05MDljLTU3NWViNmU5MjhmZCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MjQzOTMwMzYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0ZMMjY1WmVFeiIsImV4cCI6MTYyNDM5NjY3MywiaWF0IjoxNjI0MzkzMDczLCJqdGkiOiIyNzdiZTUzMi0wYWU2LTQ2ZjMtODA2ZS02MTAyMWQ5NzhjYTQiLCJjbGllbnRfaWQiOiI0bDltOHFtNnBzN2xubjZ2aHRscDh0b3MwIiwidXNlcm5hbWUiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYifQ.l9hIfyJ9-AM6qfcTItgsz9CsxqI9ORSQmdcc_apsH7MLpsjjnZVVt0TZTsvpyO_gWidf5S--1dSPvrcT2yzuCIZjlpfS2ATGmlTEwRZZ6FL0fCa9kQLmUBDD_Lf6Sl9YR_f91fi2gvwyFnJM0m3uW7xurnSzYb769jQSjj2oIruD96jYT6S-65hE1W6VE1lOA9t8MemJwAWUMk4CBdXtw7IuPcSwB0xKUcadGCpcdMECRc9ehz7Sw24uhmjC7WQKjvLPg7oy7E5KKUQN92g1-GGrTcORUiNqidvLTrOMkdMAoasGdT6DQBC4VcCg3wMkDOle1BCYsxSmnt8BMnAXwg',
        ExpiresIn: 3600,
        IdToken:
          'eyJraWQiOiJKRlFQNHFXZmJrSEp6bTFYaVV0c3VZK3ZuQnhxblJYNEpacDJIZU9QOUpRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJhdWQiOiI0bDltOHFtNnBzN2xubjZ2aHRscDh0b3MwIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImV2ZW50X2lkIjoiNGNlMjBhNGQtMDMxOS00MDU0LTkwOWMtNTc1ZWI2ZTkyOGZkIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MjQzOTMwMzYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0ZMMjY1WmVFeiIsImNvZ25pdG86dXNlcm5hbWUiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJleHAiOjE2MjQzOTY2NzMsImlhdCI6MTYyNDM5MzA3MywiZW1haWwiOiJyb2xhbmQubmVtZXRoQGJpbmdhLmh1In0.CZwWXeSFW-DdlYB7XvlSp0olHjIYc1dQ-AaTFQ-TC62CP7dzlXwZ-Ln5vyVlaf7deXYn1jttBSKXn7rpZt8dCgHXgjn5H48Bh1plUWGwef_IBh1VgTTnLtGZohjTJgCw_EVGh1iDFTwlhXCW9ez860PktevjuMo14EHCBCEd3Y9Y1MYUgHWyh60RLcupR1_h5b2g9Sjd24G8l2buzEflsB5X3LYMC1TYolr0LtRmIkGmCS76s4PpiW-OTGogpIGq2fj9OkC9jovNk8y8-qk-yvAwH2x0X7tn8hhDQp2bAahSiy-H09plgLGrIei83aZCIpvTdvamPuXLUKHqijpIHw',
        TokenType: 'Bearer',
      },
      ChallengeParameters: {},
    })
  } else if (body.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
    if (!successfulNewPassword) {
      return HttpResponse.json({}, { status: 400 })
    }
    return HttpResponse.json({
      AuthenticationResult: {
        AccessToken:
          'eyJraWQiOiJFNTFIZUNLUzFCTUgybzBrNkR1UWV5dGMycFZSVjlqd3BrVTIrWTJLNlFVPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJldmVudF9pZCI6IjRjZTIwYTRkLTAzMTktNDA1NC05MDljLTU3NWViNmU5MjhmZCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MjQzOTMwMzYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0ZMMjY1WmVFeiIsImV4cCI6MTYyNDM5NjYzNiwiaWF0IjoxNjI0MzkzMDM2LCJqdGkiOiI0ZDQyMWI1YS0zOWU4LTRhYzQtYjUzMy01OGVhYTZkZGY3ZGQiLCJjbGllbnRfaWQiOiI0bDltOHFtNnBzN2xubjZ2aHRscDh0b3MwIiwidXNlcm5hbWUiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYifQ.DIMD20hk6lNiFbCyZRpinHcFZnUcLh62uKJMjrVbAHagmeuwKHJa0YgeJ59kx0fF71Jo-mm5N1M1Bt05k35uwX_nfBdYYWXzALASFNtnzrKSSsPG_WvwawOLqrKnonvelTo4GhLqCbsrUDJNimLS_NTnqgu-T6OVgEnpCvaM8g3ZBEO2dN_nqR4Zyuq0HoLNrAIhFTYukYiYWJAqTcAilSwx-3Ilhupv9jyQtrmFrBR4gnubyMEt4I14yieBZ1rR-XIPIBq8b-vqhWlzLDspb_lXP81EVVM6MhHnD9YigVm92h4MFzeF6o9fxWvNEbe5GJNmygIKBGiLX-4Msm-4gQ',
        ExpiresIn: 3600,
        IdToken:
          'eyJraWQiOiJKRlFQNHFXZmJrSEp6bTFYaVV0c3VZK3ZuQnhxblJYNEpacDJIZU9QOUpRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJhdWQiOiI0bDltOHFtNnBzN2xubjZ2aHRscDh0b3MwIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImV2ZW50X2lkIjoiNGNlMjBhNGQtMDMxOS00MDU0LTkwOWMtNTc1ZWI2ZTkyOGZkIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MjQzOTMwMzYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0ZMMjY1WmVFeiIsImNvZ25pdG86dXNlcm5hbWUiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJleHAiOjE2MjQzOTY2MzYsImlhdCI6MTYyNDM5MzAzNiwiZW1haWwiOiJyb2xhbmQubmVtZXRoQGJpbmdhLmh1In0.fft8bKZvVAZYMyZKAT-XkT8eZKzNBQdQmX5fZ9ILdo7bGNpSyn0mLueYegOilVPcJw1Ey0VtBw_zfuhmGS9TeAFOqPwK-AzGf4XQU_jIB2cAs_JfTzBGKObz94v47kRx3L0XEu-DS6vlJ3ZH0QoSHPLjnp96jldC6PBfcNsKKtDiyocGVnknGicvUyRoIJ3CgMNAUA4q-knLrmeX6mErtQpQAbsBdUG8JB_oajW074Ut1YQdHk2CwaDJHbnJKJkxEgE6JAERneURgUHwd5c_6aVWd7gTONVrZ4H5-KLUJpGNUoSwFbU0Q0xP92Vu_wisXqcsSVGcN6UBMzGhComLfw',
        RefreshToken:
          'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.5P56kbEulczxbSoilpddU5Z1-sbdicwnCe8TeIVj350qKgeJe_kha8Zpm9_1jGj_8xDz0pezXdADYcV28787ck8rTBY-Y6niAcEkdP4bN-qjYFuVwghoYbKCiK7412Vik1KTeHdByq9KWospImTp5AtUYLE9Z7PmVOSDlplVMEZy8Rjyg7UA3SkrAXziy0HBvI5IwQ4Mn7ARqlL9p8cAwytaZqDOTul9hKO5GPWLbsnhdsQybfHj3m9LGK1Fnm3ScQAH3CFw_DeF2pz-zvzzI6uFcs1J-fC0VputFQlX2dawMAQ-BGsMFxz30sgLCP2YhBpYG_C52afb8zm0CYET3Q.G0UalPvSvZCAVZDV.tljyUPloaqcyTf6U_-DlRsf6wCmHBXJx78Vj2USB36dG1L8r0MRV0wshYlbXhFOPkNg33olC7U0Rc-wPDjBqToE108IVXtZcYlBrIq5gfBdT6mmwo3zBFLny-W9_BVsouq1dp3OZtHFmbsMOcNKzB7gj5f-mql554dWdBdfLnr3S15nugw6dTp9bFL651AuFBQt1DmFWLgxkIcQFRhAR7LHYVpDg4haaeJxUuhjwZBRZt98lxVgqa1yZxZK6J_OnJOD1SERywsuTbK0O-Y1nAbE9tBStyotxKCilnk0R7LIuXpjWf2TqmAJVfd9frrmpn-W1qOjlxISUlWMsidaRPRYpe5ym-QtX8fI7vjlIK-8lcJcaaXtyd0DUtK2FoYwHdetYjqQFxLfiuVz4mF52J_a4WkYPl5-TIj5lEcNBQFxOIE3LS3SuxEA3NqnCMtOblua10d22rO5DxfuqpwZg91IRNer-L05G53ZphQ_08SCiSa6Prddoob4DYZHsViLWJcoyQB4uJU62qeSkPslKhaJ7Xq352JMYgKRCg8wGU8mliJbirrUA9usGy4cb8Nf8Zoppf9EzM0ko53uuokgskKyubl_w-wb3_BLUEZjjFNFXnsRI25q0K3Po5OswbtE28qANhUeVN2Opd4WJVqJXhsbtEiJerhDziBE5VTu1CBPOJwVwzsGne6BvfTY1Ya8gpZUpgJdV3fGi88lT0-DUG6caqgqs1BDqFhkJRL3WmKCbABtRwXlpOUneJTWSXyBe97Ljr_ctY8YDYXjEupvAR3JPB7m1jOrRCMUkkYqL8AzXJyVsGR3_CTM2AK3Wi9Wuhg9BBW6R2tZiRAZ7zb1ZzpowDKjcMYq7wTaa7w2eFKC6VmAlHZguf4NWU2fnKa8XE3nTAotQT0Fgs2OkESdqU7gmXUgU8oqoJ6Mle14_0UIeoOvG-68rGsYpmKWlVHQ29eNF8eMGcUJUmIyF0j1cKxxYO8PYaA8pX9QFFn1ZSm9D9jlfeoudMRF_mMAzWEeQ7dWdbBVSKNK2Gf3_n-cr7U-VxSLsINukLdKRsU4S_h0-atmwtIagurCJ6z3w4caKRQhg9AxBuTZk3JLdUZk3dsytXuhW2GSDNibc37cuVym7TwBQE_TgT8JFNXDVSPMtQLLgF6-G8xUnBI7AjPqVnMbldrMG8gRkTJm-tRWiAYfq8lxZvw4J5y9vDDDtFq9UJLpXwq-J8LuW321IoBHv6WGPJhhXe4d5y0MPq5wi7BukPD1HW6gHj4EFOUDxc2617ceQrgglwXjadD-x2KhmqO0rJh8Dc-9kJ_WcgWmX4muVNN7pj4Njm-ndR9A4J5Hf8sQ.B76DczvHbYZR9G7uZSZ-7A',
        TokenType: 'Bearer',
      },
      ChallengeParameters: {},
    })
  } else if (body.ChallengeName === 'PASSWORD_VERIFIER') {
    if (forceChangePassword) {
      return HttpResponse.json({
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ChallengeParameters: {
          requiredAttributes: '[]',
          userAttributes:
            '{"email_verified":"true","email":"6uliver@gmail.com"}',
        },
        Session:
          'AYABeJtzHFgbbEI_qC6Bs5d5proAHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMATmFybjphd3M6a21zOmV1LWNlbnRyYWwtMTo1OTA0OTA4MDk4NTg6a2V5LzRkMmU1YTdmLTFjZDctNDljOS04ZmFjLTJkOWRjYjVjZWY5ZgC4AQIBAHhQusDzyeO441pddgFIDK7ZAPxNnw1aWOkhmY8j2QJruQGSH8PM966ixhZyIMh2bNIJAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMOk8gHlUk_h8eJ0oZAgEQgDuGdNAkRp3qqOpziDDERLUS1IksOQH_Tm5WZZ_pKXgz7IoJhVsrkrPVcLPYKFfcwyPbDMouDnhuLBOOdQIAAAAADAAAEAAAAAAAAAAAAAAAAAA4VWCYtld6K-QndHf953uh_____wAAAAEAAAAAAAAAAAAAAAEAAADVU_WR3E3InVCeyIBnUun7v-iPy2FMFv-_NHFoyasak8EJxY6ggMN6X17klar5uVD4dUFFSilyH7jwRPB1PEimvCDuzQm4Ek8fIZmFpAilWAd-t_NtY0EnQgv5OUSeUK3ql_gE9T6ygE-uym9BgiG1WsdgfaBUguKv_3PFdr3alwfMvWEdren4kU5pKzfEe7v5CyROAwuHlI8r6omOn1nJItyqcSRfSkNIJ7MGwze1N_iHIoCPjG6te60vn9P0hsWAeOwP9EO94fPfG0w0MOVCTOczl2QAz8U6xLwT3A0CK4FFB2u3pg',
      })
    } else {
      return HttpResponse.json({
        AuthenticationResult: {
          AccessToken:
            'eyJraWQiOiJFNTFIZUNLUzFCTUgybzBrNkR1UWV5dGMycFZSVjlqd3BrVTIrWTJLNlFVPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJldmVudF9pZCI6IjRjZTIwYTRkLTAzMTktNDA1NC05MDljLTU3NWViNmU5MjhmZCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MjQzOTMwMzYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0ZMMjY1WmVFeiIsImV4cCI6MTYyNDM5NjYzNiwiaWF0IjoxNjI0MzkzMDM2LCJqdGkiOiI0ZDQyMWI1YS0zOWU4LTRhYzQtYjUzMy01OGVhYTZkZGY3ZGQiLCJjbGllbnRfaWQiOiI0bDltOHFtNnBzN2xubjZ2aHRscDh0b3MwIiwidXNlcm5hbWUiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYifQ.DIMD20hk6lNiFbCyZRpinHcFZnUcLh62uKJMjrVbAHagmeuwKHJa0YgeJ59kx0fF71Jo-mm5N1M1Bt05k35uwX_nfBdYYWXzALASFNtnzrKSSsPG_WvwawOLqrKnonvelTo4GhLqCbsrUDJNimLS_NTnqgu-T6OVgEnpCvaM8g3ZBEO2dN_nqR4Zyuq0HoLNrAIhFTYukYiYWJAqTcAilSwx-3Ilhupv9jyQtrmFrBR4gnubyMEt4I14yieBZ1rR-XIPIBq8b-vqhWlzLDspb_lXP81EVVM6MhHnD9YigVm92h4MFzeF6o9fxWvNEbe5GJNmygIKBGiLX-4Msm-4gQ',
          ExpiresIn: 3600,
          IdToken:
            'eyJraWQiOiJKRlFQNHFXZmJrSEp6bTFYaVV0c3VZK3ZuQnhxblJYNEpacDJIZU9QOUpRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJhdWQiOiI0bDltOHFtNnBzN2xubjZ2aHRscDh0b3MwIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImV2ZW50X2lkIjoiNGNlMjBhNGQtMDMxOS00MDU0LTkwOWMtNTc1ZWI2ZTkyOGZkIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MjQzOTMwMzYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0ZMMjY1WmVFeiIsImNvZ25pdG86dXNlcm5hbWUiOiIxYWUxYzY3Yy1mNzhiLTQzNWUtYmE5YS00ODNmMDM1ZTc0NmYiLCJleHAiOjE2MjQzOTY2MzYsImlhdCI6MTYyNDM5MzAzNiwiZW1haWwiOiJyb2xhbmQubmVtZXRoQGJpbmdhLmh1In0.fft8bKZvVAZYMyZKAT-XkT8eZKzNBQdQmX5fZ9ILdo7bGNpSyn0mLueYegOilVPcJw1Ey0VtBw_zfuhmGS9TeAFOqPwK-AzGf4XQU_jIB2cAs_JfTzBGKObz94v47kRx3L0XEu-DS6vlJ3ZH0QoSHPLjnp96jldC6PBfcNsKKtDiyocGVnknGicvUyRoIJ3CgMNAUA4q-knLrmeX6mErtQpQAbsBdUG8JB_oajW074Ut1YQdHk2CwaDJHbnJKJkxEgE6JAERneURgUHwd5c_6aVWd7gTONVrZ4H5-KLUJpGNUoSwFbU0Q0xP92Vu_wisXqcsSVGcN6UBMzGhComLfw',
          RefreshToken:
            'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.5P56kbEulczxbSoilpddU5Z1-sbdicwnCe8TeIVj350qKgeJe_kha8Zpm9_1jGj_8xDz0pezXdADYcV28787ck8rTBY-Y6niAcEkdP4bN-qjYFuVwghoYbKCiK7412Vik1KTeHdByq9KWospImTp5AtUYLE9Z7PmVOSDlplVMEZy8Rjyg7UA3SkrAXziy0HBvI5IwQ4Mn7ARqlL9p8cAwytaZqDOTul9hKO5GPWLbsnhdsQybfHj3m9LGK1Fnm3ScQAH3CFw_DeF2pz-zvzzI6uFcs1J-fC0VputFQlX2dawMAQ-BGsMFxz30sgLCP2YhBpYG_C52afb8zm0CYET3Q.G0UalPvSvZCAVZDV.tljyUPloaqcyTf6U_-DlRsf6wCmHBXJx78Vj2USB36dG1L8r0MRV0wshYlbXhFOPkNg33olC7U0Rc-wPDjBqToE108IVXtZcYlBrIq5gfBdT6mmwo3zBFLny-W9_BVsouq1dp3OZtHFmbsMOcNKzB7gj5f-mql554dWdBdfLnr3S15nugw6dTp9bFL651AuFBQt1DmFWLgxkIcQFRhAR7LHYVpDg4haaeJxUuhjwZBRZt98lxVgqa1yZxZK6J_OnJOD1SERywsuTbK0O-Y1nAbE9tBStyotxKCilnk0R7LIuXpjWf2TqmAJVfd9frrmpn-W1qOjlxISUlWMsidaRPRYpe5ym-QtX8fI7vjlIK-8lcJcaaXtyd0DUtK2FoYwHdetYjqQFxLfiuVz4mF52J_a4WkYPl5-TIj5lEcNBQFxOIE3LS3SuxEA3NqnCMtOblua10d22rO5DxfuqpwZg91IRNer-L05G53ZphQ_08SCiSa6Prddoob4DYZHsViLWJcoyQB4uJU62qeSkPslKhaJ7Xq352JMYgKRCg8wGU8mliJbirrUA9usGy4cb8Nf8Zoppf9EzM0ko53uuokgskKyubl_w-wb3_BLUEZjjFNFXnsRI25q0K3Po5OswbtE28qANhUeVN2Opd4WJVqJXhsbtEiJerhDziBE5VTu1CBPOJwVwzsGne6BvfTY1Ya8gpZUpgJdV3fGi88lT0-DUG6caqgqs1BDqFhkJRL3WmKCbABtRwXlpOUneJTWSXyBe97Ljr_ctY8YDYXjEupvAR3JPB7m1jOrRCMUkkYqL8AzXJyVsGR3_CTM2AK3Wi9Wuhg9BBW6R2tZiRAZ7zb1ZzpowDKjcMYq7wTaa7w2eFKC6VmAlHZguf4NWU2fnKa8XE3nTAotQT0Fgs2OkESdqU7gmXUgU8oqoJ6Mle14_0UIeoOvG-68rGsYpmKWlVHQ29eNF8eMGcUJUmIyF0j1cKxxYO8PYaA8pX9QFFn1ZSm9D9jlfeoudMRF_mMAzWEeQ7dWdbBVSKNK2Gf3_n-cr7U-VxSLsINukLdKRsU4S_h0-atmwtIagurCJ6z3w4caKRQhg9AxBuTZk3JLdUZk3dsytXuhW2GSDNibc37cuVym7TwBQE_TgT8JFNXDVSPMtQLLgF6-G8xUnBI7AjPqVnMbldrMG8gRkTJm-tRWiAYfq8lxZvw4J5y9vDDDtFq9UJLpXwq-J8LuW321IoBHv6WGPJhhXe4d5y0MPq5wi7BukPD1HW6gHj4EFOUDxc2617ceQrgglwXjadD-x2KhmqO0rJh8Dc-9kJ_WcgWmX4muVNN7pj4Njm-ndR9A4J5Hf8sQ.B76DczvHbYZR9G7uZSZ-7A',
          TokenType: 'Bearer',
        },
        ChallengeParameters: {},
      })
    }
  } else if (body.AccessToken !== undefined) {
    return HttpResponse.json({
      UserAttributes: [
        {
          Name: 'sub',
          Value: userId,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
        {
          Name: 'email',
          Value: '6uliver@gmail.com',
        },
      ],
      Username: userId,
    })
  } else if (body.ConfirmationCode !== undefined) {
    if (!successfulResetPassword) {
      return HttpResponse.json({}, { status: 400 })
    }

    return HttpResponse.json({})
  } else if (body.Username !== undefined) {
    if (!successfulForgetPassword) {
      return HttpResponse.json({}, { status: 400 })
    }

    return HttpResponse.json({
      CodeDeliveryDetails: {
        AttributeName: 'email',
        DeliveryMedium: 'EMAIL',
        Destination: body.Username,
      },
    })
  }
}
