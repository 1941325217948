import { useCallback } from 'react'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Card } from 'src/components/Card'
import { PulsatingDot } from 'src/components/PulsatingDot'
import { gql } from 'src/graphql'
import { ConnectionStatus } from 'src/graphql/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { PlaylistInfo } from './PlaylistInfo'

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`

const Left = styled.div`
  flex: 1;
  margin: 0 10px;
  width: 50%;
`

const Label = styled.div`
  color: ${(props) => props.theme.colors.blue};
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Title = styled.div`
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const DotContainer = styled.div`
  margin-right: 6px;
`

export const ScreenItem = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment ScreenItemFragment on Screen {
        id
        name
        slideshow {
          id
          name
          slides {
            media {
              id
              thumbnailUrl
            }
          }
        }
        presence {
          status
        }
      }
    `),
  },
  function ScreenItem({ screen: { id, name, slideshow, presence } }) {
    const playlist = slideshow?.name
    const image = slideshow?.slides[0]?.media.thumbnailUrl ?? null
    const { t } = useTranslation()

    const navigate = useNavigate()

    const navigateToScreen = useCallback(() => {
      navigate(`/screens/${id}`)
    }, [navigate, id])

    const showScreenStatus = useFlag('show-screen-presence-status-77')

    return (
      <Card
        data-test="screen-card"
        image={image}
        hideOverlay={playlist === undefined}
        imageChildren={<PlaylistInfo playlist={playlist} />}
        onClick={navigateToScreen}
      >
        <ContentWrapper>
          {showScreenStatus && (
            <DotContainer>
              <PulsatingDot
                danger={presence.status !== ConnectionStatus.Connected}
              />
            </DotContainer>
          )}
          <Left>
            {!showScreenStatus && <Label>{t('screens.name')}:</Label>}
            <Title>{name}</Title>
          </Left>
        </ContentWrapper>
      </Card>
    )
  }
)
