import { useEffect, useState } from 'react'
import { getConfig, Config } from 'src/config'

export function useConfig() {
  const [config, setConfig] = useState<Config | null>(null)

  useEffect(() => {
    async function updateConfig() {
      setConfig(await getConfig())
    }

    void updateConfig()
  })

  return config
}
