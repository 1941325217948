import { useTranslation } from 'react-i18next'

interface Props {
  regStatus: string
}

export function RegistrationStatus({ regStatus }: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'users.registrationStatus',
  })

  switch (regStatus) {
    case 'CONFIRMED':
      return <>{t('confirmed')}</>
    case 'UNCONFIRMED':
      return <>{t('unconfirmed')}</>
    case 'ARCHIVED':
      return <>{t('archived')}</>
    case 'COMPROMISED':
      return <>{t('compromised')}</>
    case 'UNKNOWN':
      return <>{t('unknown')}</>
    case 'RESET_REQUIRED':
      return <>{t('resetRequired')}</>
    case 'FORCE_CHANGE_PASSWORD':
      return <>{t('forceChangePassword')}</>
    case 'EXTERNAL_PROVIDER':
      return <>{t('externalProvider')}</>
    default:
      return <>{t('unknown')}</>
  }
}
