import { useCallback } from 'react'
import { useOpener } from 'src/hooks/useOpener'
import { AddScreenBox } from './AddScreenBox'
import { AddScreenModal } from './AddScreenModal'

export interface AddScreenProps {
  onAddScreen: (screenName: string) => void
}

export function AddScreen({ onAddScreen }: AddScreenProps) {
  const [isAddScreenOpened, openAddScreen, closeAddScreen] = useOpener('add')

  const addScreen = useCallback(
    async (screenName: string) => {
      await onAddScreen(screenName)
      closeAddScreen()
    },
    [onAddScreen, closeAddScreen]
  )

  return (
    <>
      <AddScreenBox onAddScreen={openAddScreen} />
      <AddScreenModal
        onAddScreen={addScreen}
        onClose={closeAddScreen}
        show={isAddScreenOpened}
      />
    </>
  )
}
