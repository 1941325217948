import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { gql } from 'src/graphql'
import {
  withArray,
  withFragments,
  withUndefined,
} from 'src/libraries/graphql-fragments'
import { ScreenItem } from './ScreenItem'
import { AddScreenBox } from './add-screen/AddScreenBox'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

const ScreenItemWrapper = styled.div`
  margin-bottom: 30px;
  padding: 0 15px;
  min-height: 220px;
  flex: 1 1 100%;
  max-width: ${100 / 3}%;

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`

export const ScreenList = withFragments()(
  {
    screens: withUndefined(
      withArray(
        gql(/* GraphQL */ `
          fragment ScreenListFragment on Screen {
            id
            ...ScreenItemFragment
          }
        `)
      )
    ),
  },
  function ScreenList({ screens }) {
    const navigate = useNavigate()
    const addScreen = useCallback(() => navigate('/screens/pair'), [navigate])

    return (
      <Container>
        {screens?.map((screen) => (
          <ScreenItemWrapper key={`screen-${screen.id}`}>
            <ScreenItem screen={screen} />
          </ScreenItemWrapper>
        ))}
        <ScreenItemWrapper key="add-screen">
          <AddScreenBox onAddScreen={addScreen} />
        </ScreenItemWrapper>
      </Container>
    )
  }
)
