import React, { useCallback } from 'react'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { selectedOrganizationIdAtom } from './selectedOrganizationIdAtom'

interface Props {
  id: string
}

export function SelectOrganization({ id }: Props) {
  const { t } = useTranslation()
  const setSelectedOrganizationId = useSetAtom(selectedOrganizationIdAtom)

  const handleSelectOrganization = useCallback(
    () => setSelectedOrganizationId(id),
    [setSelectedOrganizationId, id]
  )

  return (
    <span onClick={handleSelectOrganization}>{t('organizations.select')}</span>
  )
}
