import { InputHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { Link } from 'src/elements/form/Link'

export interface FormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  requiredMessage?: string
  label: string
  linkTitle?: string
  link?: string
}

export function FormField({
  name,
  requiredMessage,
  label,
  link,
  linkTitle,
  ...props
}: FormFieldProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormGroup>
      <Label error={errors[name]?.message}>{label}</Label>
      <Input
        invalid={errors[name] !== undefined}
        {...register(name, {
          required: requiredMessage,
        })}
        {...props}
      />
      {link !== undefined && <Link to={link}>{linkTitle}</Link>}
    </FormGroup>
  )
}
