import { ReactNode } from 'react'
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Box } from 'src/elements/Box'

const ImageWrapper = styled.div`
  border-radius: 5px;
  height: 120px;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = styled.div`
  background-color: ${(props) => props.theme.colors.darkBlue};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`

interface ImageContentProps {
  dark: boolean
}

const ImageContent = styled.div<ImageContentProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${(props) =>
    props.dark ? props.theme.colors.blue : props.theme.colors.white};
`

const Image = styled.img`
  object-fit: cover;

  width: 100%;
  height: 100%;
`

const ImagePlaceholderIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
`

const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`

export interface CardProps {
  imageChildren?: ReactNode
  children?: ReactNode
  image: string | null
  showPlaceholderIcon?: boolean
  hideOverlay?: boolean
  onClick?: () => void
}

export function Card({
  image = null,
  imageChildren,
  children,
  showPlaceholderIcon = false,
  hideOverlay = false,
  onClick,
  ...props
}: CardProps) {
  const showOverlay = !hideOverlay
  return (
    <Box smallPadding onClick={onClick} {...props}>
      <ImageWrapper data-test="image-wrapper">
        {image !== null ? (
          <Image src={image} alt="" />
        ) : (
          showPlaceholderIcon && <ImagePlaceholderIcon icon={faPhotoVideo} />
        )}
        {imageChildren !== undefined && (
          <>
            {showOverlay && <Overlay />}
            <ImageContent dark={!showOverlay}>{imageChildren}</ImageContent>
          </>
        )}
      </ImageWrapper>
      {children !== undefined && <Content>{children}</Content>}
    </Box>
  )
}
