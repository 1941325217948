import { useEffect } from 'react'
import { useFlagsStatus } from '@unleash/proxy-client-react'
import { ConfiguredApp } from './ConfiguredApp'
import { useAuthStore } from './auth/useAuthStore'
import { Loader } from './components/Loader'
import { Config } from './config'
import { unleashClient } from './feature-flags/unleashClient'

interface Props {
  config: Config | null
}

export function FeatureFlaggedApp({ config }: Props) {
  const { flagsReady } = useFlagsStatus()

  const user = useAuthStore((state) => state.user)

  useEffect(() => {
    if (!user) {
      void unleashClient.updateContext({ userId: undefined })
      return
    }

    user.getUserAttributes((_err, data) => {
      const userId =
        data?.find((attr) => attr.Name === 'email')?.Value ?? user.getUsername()

      void unleashClient.updateContext({ userId })
    })
  }, [user])

  return flagsReady && config !== null ? (
    <ConfiguredApp config={config} />
  ) : (
    <Loader />
  )
}
