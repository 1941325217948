import styled, { keyframes } from 'styled-components'

const pulseRing = keyframes`
    0% {
      transform: scale(0.33);
    }
    50% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      opacity: 0;
    }
  `

const Dot = styled.div<{ $danger: boolean }>`
  position: relative;
  width: 16px;
  height: 16px;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: -8px;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    border-radius: 45px;
    background-color: ${({ $danger }) => ($danger ? '#cc5252' : '#98d99d')};
    animation: ${pulseRing} 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: relative;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ $danger }) => ($danger ? '#ff6666' : '#aaf2b0')};
    border-radius: 8px;
  }
`

interface Props {
  danger?: boolean
}

export function PulsatingDot({ danger = false }: Props) {
  return <Dot $danger={danger} />
}
