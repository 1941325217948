import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Label } from 'src/elements/form/Label'
import { Select } from 'src/elements/form/Select'
import { gql } from 'src/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { selectedOrganizationIdAtom } from 'src/organizations/selectedOrganizationIdAtom'

export const PlaylistSelector = withFragments()(
  {
    screen: gql(`
      fragment ScreenPlaylistSelectorFragment on Screen {
        id
        slideshow {
          id
          name
        }
      }
    `),
  },
  function PlaylistSelector({ screen }) {
    const { t } = useTranslation()
    const selectedOrganizationId = useAtomValue(selectedOrganizationIdAtom)

    if (selectedOrganizationId === null) {
      throw new Error('No organization selected')
    }

    const playlistQuery = useQuery(
      gql(/* GraphQL */ `
        query getSlideshowsQuery($organizationId: String!) {
          slideshows(organizationId: $organizationId) {
            id
            name
          }
        }
      `),
      {
        variables: {
          organizationId: selectedOrganizationId,
        },
      }
    )

    const playlists = Object.fromEntries(
      playlistQuery.data?.slideshows.map((slideshow) => [
        slideshow.id,
        slideshow.name,
      ]) ?? []
    )

    const [setSlideshowMutation, result] = useMutation(
      gql(/* GraphQL */ `
        mutation setSlideshow($screenId: ID!, $slideshowId: ID) {
          setSlideshow(screenId: $screenId, slideshowId: $slideshowId) {
            id
            slideshow {
              id
            }
          }
        }
      `)
    )

    const id = screen.id

    const selectPlaylist = useCallback(
      async (playlistId: string | null) => {
        await setSlideshowMutation({
          variables: {
            screenId: id,
            slideshowId: playlistId,
          },
        })
      },
      [id, setSlideshowMutation]
    )

    return (
      <FormGroup data-test="playlist-select-wrapper">
        <Label loading={result.loading}>
          {t('screens.edit.settings.playlist.label')}
        </Label>
        <Select
          placeholder={t('screens.playlists.selector.playlistPlaceholder')}
          emptyTitle={t('screens.playlists.selector.noPlaylistSelected')}
          value={screen.slideshow?.id ?? null}
          onChange={selectPlaylist}
          options={playlists}
        />
      </FormGroup>
    )
  }
)
