/* import { useMemo } from 'react' */
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SubTitle } from 'src/elements/SubTitle'
import { gql } from 'src/graphql'
import { ConnectionStatus } from 'src/graphql/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { StatusIcon } from './StatusIcon'

const StatusWrapper = styled(SubTitle)`
  margin-top: -20px;
`

const IconWrapper = styled.div`
  margin-left: 33px;
  display: inline-block;
  vertical-align: middle;
`

export const Status = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment PresenceFragment on Screen {
        id
        presence {
          status
          lastActivity
        }
      }
    `),
  },
  function Status({ screen: { presence } }) {
    const { t } = useTranslation()

    const last =
      presence.lastActivity !== null
        ? moment(presence.lastActivity).fromNow()
        : '--'

    return (
      <StatusWrapper>
        {presence.status === ConnectionStatus.Connected
          ? t('screens.edit.status.connected', {
              last,
            })
          : t('screens.edit.status.disconnected', {
              last,
            })}
        <IconWrapper>
          <StatusIcon status={presence.status} />
        </IconWrapper>
      </StatusWrapper>
    )
  }
)
