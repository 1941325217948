import { useCallback } from 'react'
import styled from 'styled-components'
import { Orientation } from 'src/components/icons/Icons'

const IconWrapper = styled.div<{ active: boolean }>`
  width: 64px;
  height: 64px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ active }) => (active ? '#505073' : 'transparent')};
  border-radius: 8px;
  color: ${({ active }) => (active ? '#505073' : '#B6BCBF')};
  transition: all 0.2s;

  &:hover {
    border: 2px solid #505073;
    color: #505073;
    transition: all 0.1s;
  }
`

const Icon = styled(Orientation)<{ rotation: number }>`
  transform: rotate(${({ rotation }) => -rotation}deg);
  transform-origin: center;
`

interface Props {
  currentRotation: number
  rotation: number
  onRotation: (rotation: number) => void
}

export function RotationIcon({ currentRotation, rotation, onRotation }: Props) {
  const handleRotation = useCallback(() => {
    onRotation(rotation)
  }, [onRotation, rotation])

  return (
    <IconWrapper
      active={rotation === currentRotation}
      onClick={handleRotation}
      data-test={`rotation-icon-${rotation}${rotation === currentRotation ? '-active' : ''}`}
    >
      <Icon rotation={rotation} data-test={`rotation-svg-${rotation}`} />
    </IconWrapper>
  )
}
